import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {SCHEDULE_FINAL_REVIEW_CALL} from '../../mutations';

export default function useScheduleFinalReviewCall({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [scheduleFinalReviewCall, {data, loading, error}] = useMutation(
    SCHEDULE_FINAL_REVIEW_CALL,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['scheduleFinalReviewCall'], data);
  return {scheduleFinalReviewCall, response, loading, error};
}
