import {reject} from 'lodash';

import {useEnums} from '@renofi/graphql';

export default () => {
  const {enums} = useEnums();
  const occupancyTypes = reject(
    enums?.realEstateOwnedPropertyOccupancyTypes || [],
    ['value', 'not_provided'],
  );
  const propertyStatusTypes = enums?.realEstateOwnedPropertyStatusTypes || [];
  const propertyTypes = reject(enums?.realEstateOwnedPropertyTypeTypes || [], [
    'value',
    'other',
  ]);

  return {
    occupancyTypes,
    propertyStatusTypes,
    propertyTypes,
  };
};
