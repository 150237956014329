/* eslint-disable max-lines */
import React, {useEffect, useState, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {propEq, prop, remove, isNil, pathOr, isEmpty} from 'ramda';
import {differenceInMonths, startOfDay} from 'date-fns';
import {DateField, LargeDropZone as DropZone} from 'modules/layout';

import {useQueryString} from '@renofi/utilities/src/hooks';
import {
  Box,
  Flex,
  Form,
  ModalWithButtons,
  SelectField,
  Text,
  Textarea,
} from '@renofi/components-internal';
import Progress from '@renofi/icons/src/Progress';
import Trash from '@renofi/icons/src/Trash';
import {sendEvent} from '@renofi/analytics';
import {
  useUploadFiles,
  GET_PROJECT_RENOVATION_UPDATES,
  GET_PROJECT_TIMELINE,
  useToggledBorrowerProject,
  useProjectTimeline,
  useProjectRenovationUpdatesV2,
  useCreateRenovationUpdate,
  useChangeRenovationUpdate,
} from '@renofi/graphql';
import {
  RENOVATION_UPDATE_KINDS,
  doesKindSupportDate,
  getDatePickerLabel,
  generateKindOptions,
} from '@renofi/modules-internal';

import {Photo, PhotoLink, Placeholder, Remove} from './styled';
import {isSubmitDisabled, getDetailsLabel, mapPhotos} from './utils';

const REFETCH_QUERIES = [GET_PROJECT_RENOVATION_UPDATES, GET_PROJECT_TIMELINE];
const NOW = startOfDay(new Date());

const UpdateModal = ({
  renovationUpdate,
  onClose,
  changeRenovationStateFiles,
  changeRenovationStateData,
  changeRenovationStateKind,
  changeRenovationStateDetails,
  changeRenovationStateDate,
}) => {
  const {
    kind,
    data: {date, details, files, id: renovationUpdateId},
  } = renovationUpdate;

  const {renovationUpdateStatus} = useParams();
  const queryParams = useQueryString();

  const [loading, setLoading] = useState(false);
  const [dateTooLongAgo, setDateTooLongAgo] = useState(false);

  const {project} = useToggledBorrowerProject();

  const {uploadFiles, uploading = []} = useUploadFiles({
    basePath: 'renovation_updates',
  });

  const {timeline: projectTimeline, fetch: fetchTimeline} = useProjectTimeline({
    lazy: true,
  });
  const {fetch: fetchRenovationUpdates} = useProjectRenovationUpdatesV2({
    lazy: true,
  });

  const {createRenovationUpdate} = useCreateRenovationUpdate({
    refetchQueries: REFETCH_QUERIES,
  });
  const {changeRenovationUpdate} = useChangeRenovationUpdate({
    refetchQueries: REFETCH_QUERIES,
  });

  useEffect(() => {
    (async () => {
      if (!project) {
        return;
      }

      const projectId = project.id;

      fetchTimeline({
        variables: {
          id: projectId,
        },
      });

      const result = await fetchRenovationUpdates({
        variables: {
          id: projectId,
        },
      });

      const renovationUpdates = pathOr(
        [],
        ['data', 'projectRenovationUpdatesV2'],
        result,
      );

      const foundUpdate = renovationUpdateId
        ? renovationUpdates.find(propEq('id', renovationUpdateId))
        : {};

      const renovationUpdateState = {
        date:
          renovationUpdateId && foundUpdate.date
            ? new Date(foundUpdate.date)
            : null,
        details: renovationUpdateId ? foundUpdate.details : '',
        files: mapPhotos(renovationUpdateId, foundUpdate),
      };

      changeRenovationStateData(renovationUpdateState);

      const kindFromParams = renovationUpdateStatus || queryParams?.status;

      if (renovationUpdateId) {
        changeRenovationStateKind(foundUpdate.kind);
      } else if (kindFromParams) {
        changeRenovationStateKind(kindFromParams);
      }
    })();
  }, [project]);

  const submitHandler = (event) => {
    event.preventDefault();
  };

  const onAcceptFiles = async (acceptedFiles) => {
    const uploaded = await uploadFiles(acceptedFiles);
    const finishedFiles = uploaded.map((file) => ({
      id: file.id,
      loading: false,
      fileName: file.fileName,
      objectName: file.objectName,
      url: file.url,
      thumbnail: file.url,
    }));

    const updatedFiles = files.concat(finishedFiles);
    changeRenovationStateFiles(updatedFiles);
  };

  const onChangeDetails = (value) => {
    changeRenovationStateDetails(value);
  };

  const onChangeType = (kind) => {
    changeRenovationStateKind(kind);
  };

  const onChangeDate = (date) => {
    const diff = date ? differenceInMonths(new Date(date), NOW) : 0;
    const tooLongAgo = diff < -6;

    setDateTooLongAgo(tooLongAgo);
    changeRenovationStateDate(tooLongAgo ? null : date);
  };

  const onSubmit = async () => {
    const {data, kind} = renovationUpdate || {};
    const {date, details, files, id} = data || {};

    setLoading(true);

    const variables = {
      date: date ? date : null,
      details,
      files: files.map(prop('objectName')),
      kind,
      size: 'xsmall',
    };
    const eventParams = {projectId: project.id, type: kind};

    try {
      if (id) {
        await changeRenovationUpdate({
          variables: {
            id,
            ...variables,
          },
        });
        sendEvent('Secure/Renovation-Update-Updated', eventParams);
      } else {
        await createRenovationUpdate({
          variables: {
            projectId: project.id,
            ...variables,
          },
        });
        sendEvent('Secure/Renovation-Update-Submitted', eventParams);
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const onRemoveFile = (idx) => {
    const updateFiles = remove(idx, 1, files || []);

    changeRenovationStateFiles(updateFiles);
  };

  const typeOptions = useMemo(() => {
    if (isNil(projectTimeline)) {
      return [];
    }

    return generateKindOptions(projectTimeline);
  }, [projectTimeline]);

  const isCompleted = kind === RENOVATION_UPDATE_KINDS.renovation_completed;
  const datePickerLabel = getDatePickerLabel(kind);
  const hasDate = doesKindSupportDate(kind);

  const disabled = isSubmitDisabled(renovationUpdate) || !isEmpty(uploading);

  return (
    <ModalWithButtons
      show
      width={600}
      loading={loading}
      disabled={disabled}
      onClose={onClose}
      onReject={onClose}
      onAccept={onSubmit}
      header="New renovation update"
      acceptLabel="Submit update">
      <Form onSubmit={submitHandler}>
        <Flex width={1}>
          <Box width={1 / 2} pr={12}>
            <SelectField
              value={kind || ''}
              options={typeOptions}
              label="Renovation status"
              placeholder="Select"
              onChange={onChangeType}
            />
          </Box>
          <Box width={1 / 2} pl={12}>
            {hasDate ? (
              <DateField
                value={date}
                leftIcon="flag"
                local={false}
                error={
                  dateTooLongAgo
                    ? 'Dates should not be older than 6 months'
                    : null
                }
                label={datePickerLabel}
                onChange={onChangeDate}
              />
            ) : null}
          </Box>
        </Flex>
        {!isCompleted ? (
          <>
            <Textarea
              rows={5}
              value={details}
              label={getDetailsLabel(kind)}
              onChange={onChangeDetails}
              active
            />
            <DropZone
              compact
              accept={['image/*']}
              buttonLabel="Add photos"
              message="Drop photos here or use the Add photos button"
              onAcceptFiles={onAcceptFiles}
              onRejectFiles={() => {}}
            />
          </>
        ) : null}
        {isCompleted ? (
          <Text fontSize={16} lineHeight="24px">
            <b>Fantastic!</b>
            <br />
            You can now order the certificate of completion - the appraiser will
            reach out to you directly to schedule the day and time that works
            best for you.
            <br />
            <br />
            Once the appraiser visits your home for a super quick walk through
            to snap some pictures and confirm the renovation is completed you’re
            all done!
          </Text>
        ) : null}
        <Flex width={1}>
          {!isEmpty(files)
            ? files.map(({id, loading, thumbnail, url}, i) =>
                loading ? (
                  <Placeholder key={id}>
                    <Progress animate duration={1000} />
                  </Placeholder>
                ) : (
                  <PhotoLink blank key={id} href={url}>
                    <Remove
                      onClick={(event) => {
                        event.preventDefault();
                        onRemoveFile(i);
                      }}>
                      <Trash width={16} height={16} />
                    </Remove>
                    <Photo src={thumbnail} />
                  </PhotoLink>
                ),
              )
            : null}
        </Flex>
      </Form>
    </ModalWithButtons>
  );
};

UpdateModal.propTypes = {
  renovationUpdate: PropTypes.shape({
    kind: PropTypes.string,
    data: PropTypes.shape({
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      details: PropTypes.string,
      files: PropTypes.array,
      id: PropTypes.string,
    }),
  }),
  onClose: PropTypes.func.isRequired,
  changeRenovationStateFiles: PropTypes.func,
  changeRenovationStateData: PropTypes.func,
  changeRenovationStateKind: PropTypes.func,
  changeRenovationStateDetails: PropTypes.func,
  changeRenovationStateDate: PropTypes.func,
};

export default UpdateModal;
