import {useEffect, useState} from 'react';

import {last as lastInArray} from 'ramda';
import {isArray} from 'lodash';

export default function useWizardState(initialSteps, route) {
  const [steps, setSteps] = useState(initialSteps);

  // On route change
  useEffect(() => {
    const root = findRoot(route)?.id;
    updateStep({id: route, key: 'current', value: true, toggle: true});
    // Also set root as a current route
    updateStep({id: root, key: 'current', value: true});
    // Close childs of none current steps
    updateAllRootSteps({key: 'flat', value: true});
    updateStep({id: root, key: 'flat', value: false});
  }, [route, steps.length]);

  function toggleStep(id, value) {
    updateStep({id, key: 'hidden', value: !value});
  }

  function addStep(step, id) {
    setSteps((steps) => {
      const index = steps.findIndex((step) => step.id === id);
      if (steps.some((item) => item.id === step.id)) {
        return steps;
      }
      if (index >= 0) {
        return [...steps.slice(0, index + 1), step, ...steps.slice(index + 1)];
      }
      return [...steps, step];
    });
  }

  function removeStep(id) {
    setSteps((steps) => {
      return steps.filter((step) => step.id !== id);
    });
  }

  function updateStep({id, key, value, toggle}) {
    return setSteps((steps) => newValue({steps, id, key, value, toggle}));
  }

  function newValue({steps, id, key, value, toggle}) {
    return steps.map((step) => {
      return {
        ...setValue(step, id, key, value, toggle),
        children: step?.children?.map((child) => ({
          ...setValue(child, id, key, value, toggle),
        })),
      };
    });
  }

  function updateAllRootSteps({key, value}) {
    return setSteps((steps) => {
      return steps.map((step) => ({...step, [key]: value}));
    });
  }

  function setValue(item, id, key, value, toggle) {
    if (item.id === id) return {...item, [key]: value};
    return toggle ? {...item, [key]: !value} : item;
  }

  function findRoot(route) {
    return steps.find((step) =>
      step.children?.some((child) => child.id === route),
    );
  }

  function findStepById(id, steps = []) {
    let item;
    steps.forEach((step) => {
      if (step.id === id) item = step;
      step.children?.forEach((child) => {
        if (child.id === id) item = child;
      });
    });
    return item || {};
  }

  function setComplete(route, value) {
    updateStep({id: route, key: 'complete', value});
    const root = findRoot(route);
    const allChildCompleted = root?.children?.every(
      (child) => child.hidden || child.complete,
    );
    if (allChildCompleted) {
      updateStep({id: root.id, key: 'complete', value: true});
    }
  }

  function isBeforeCurrentStep(step, currentStep) {
    const flat = steps.flatMap((item) => [
      item,
      ...(isArray(item.children) ? item.children : []),
    ]);
    const stepIndex = flat.findIndex((item) => item.id === step.id);
    const currentIndex = flat.findIndex((item) => item.id === currentStep.id);
    return stepIndex < currentIndex;
  }

  function getRoutes() {
    const flatten = new Set();
    steps.forEach((step) => {
      if (step.hidden) return;
      if (!step.children) flatten.add(step.id);
      step.children?.forEach((child) => {
        if (child.hidden) return;
        flatten.add(child.id);
      });
    });

    const flattenArray = [...flatten];
    const currentIndex = flattenArray.indexOf(route);
    const nextIndex =
      currentIndex + 1 >= flattenArray.length ? currentIndex : currentIndex + 1;
    const prevIndex = currentIndex - 1 < 0 ? currentIndex : currentIndex - 1;

    return {
      next: flattenArray[nextIndex],
      prev: flattenArray[prevIndex],
      current: route,
      last: lastInArray(flattenArray),
    };
  }

  const {next, prev, current, last} = getRoutes();

  return {
    steps,
    current,
    next,
    prev,
    last,
    toggleStep,
    addStep,
    removeStep,
    updateStep,
    currentStep: findStepById(route),
    findStepById,
    setComplete,
    isBeforeCurrentStep,
  };
}
