import React, {Fragment, useState} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';
import {useCurrentTask} from 'modules/project';

import {Box, ButtonGroup, Text} from '@renofi/components-internal';
import {
  GET_PROJECT_TASKS,
  useSubmitAppraisalRecentTransactions,
  useToggledBorrowerProject,
} from '@renofi/graphql';
import {basic80} from '@renofi/theme/src/colors';

import RecentOccurrence from './components/RecentOccurrence';

const RecentTransactionsWizard = ({onChange, taskId}) => {
  const {project} = useToggledBorrowerProject();
  const projectId = propOr(null, 'id', project);
  const {refetchProjectTasks, task} = useCurrentTask({taskId});
  const {loading, submitAppraisalRecentTransactions} =
    useSubmitAppraisalRecentTransactions({
      refetchQueries: [
        {
          query: GET_PROJECT_TASKS,
          variables: {projectId},
        },
      ],
    });

  const transactions = propOr(null, 'appraisalRecentTransactions', task);
  const [occurredRecently, setOccuredRecently] = useState(
    propOr(null, 'occurredRecently', transactions),
  );

  const onChangeOccurence = async (occured) => {
    onChange();
    const variables = {
      appraisalRecentTransactions: {occurredRecently: occured},
      taskId,
    };
    setOccuredRecently(occured);
    await submitAppraisalRecentTransactions({variables});
    refetchProjectTasks();
  };

  return (
    <Fragment>
      <Text my={2} color={basic80} fontSize={24}>
        {task?.title}
      </Text>
      <Box width={0.5}>
        <ButtonGroup
          disabled={loading}
          value={occurredRecently}
          onChange={onChangeOccurence}
        />
      </Box>
      <RecentOccurrence onChange={onChange} taskId={taskId} />
    </Fragment>
  );
};

RecentTransactionsWizard.propTypes = {
  onChange: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default RecentTransactionsWizard;
