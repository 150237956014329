import React, {useMemo} from 'react';

import PropTypes from 'prop-types';

import {
  Alert,
  EmailField,
  MoneyField,
  PhoneField,
  TextField,
  Textarea,
} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {hasCoBorrowerInProject} from '@renofi/utilities';

import {getRequiredMessage} from '../../utils';

import {FieldBox, FieldsWrapper} from './styled';

const AutocompleteFields = ({
  contractor,
  disabled,
  invalidFields,
  isDirty,
  isEditing,
  onUpdatePhoneNumber,
  onUpdateEmail,
  onUpdateZipCode,
  onUpdate,
}) => {
  const isDisabled = isEditing ? disabled : !isDirty || disabled;
  const {project} = useToggledBorrowerProject();

  const showEmailWarning = useMemo(() => {
    const {email} = contractor || {};
    const borrowerEmail = project?.primaryApplicant?.email;
    const hasCoborrower = hasCoBorrowerInProject(project);

    if (!hasCoborrower) {
      return Boolean(email) && email === borrowerEmail;
    }

    const coborrowerEmail = project?.coborrower?.email;
    return (
      Boolean(email) && (email === borrowerEmail || email === coborrowerEmail)
    );
  }, [JSON.stringify({email: contractor?.email, project})]);

  return (
    <>
      {showEmailWarning ? (
        <Alert mb={3} variant="warning">
          The email entered below will be used to invite the contractor
          performing the work to complete an online questionnaire as required
          for our due diligence process
        </Alert>
      ) : null}

      <FieldsWrapper>
        <FieldBox>
          <TextField
            active
            autoComplete="none"
            disabled={isDisabled}
            error={
              isDirty ? getRequiredMessage('contactName', invalidFields) : null
            }
            label="Name of contact person"
            onChange={(v) => onUpdate('contactName', v)}
            tabIndex={2}
            value={contractor?.contactName}
          />
        </FieldBox>
        <FieldBox>
          <PhoneField
            active
            autoComplete="none"
            disabled={isDisabled}
            error={
              isDirty && invalidFields.includes('phoneNumber')
                ? 'Invalid phone number'
                : null
            }
            label="Phone number"
            onChange={onUpdatePhoneNumber}
            tabIndex={3}
            value={contractor?.phoneNumber}
          />
        </FieldBox>
        <FieldBox>
          <EmailField
            active
            autoComplete="none"
            disabled={isDisabled}
            error={
              isDirty && invalidFields.includes('email')
                ? 'Invalid email'
                : null
            }
            label="Email address"
            onChange={onUpdateEmail}
            tabIndex={4}
            value={contractor?.email}
          />
        </FieldBox>
      </FieldsWrapper>

      <FieldsWrapper>
        <FieldBox>
          <TextField
            active
            autoComplete="none"
            disabled={isDisabled}
            error={
              isDirty
                ? getRequiredMessage('streetAddress', invalidFields)
                : null
            }
            label="Street address"
            onChange={(v) => onUpdate('streetAddress', v)}
            tabIndex={5}
            value={contractor?.streetAddress}
          />
        </FieldBox>
        <FieldBox>
          <TextField
            active
            autoComplete="none"
            disabled={isDisabled}
            error={isDirty ? getRequiredMessage('city', invalidFields) : null}
            label="City"
            onChange={(v) => onUpdate('city', v)}
            tabIndex={6}
            value={contractor?.city}
          />
        </FieldBox>
        <FieldBox>
          <TextField
            active
            autoComplete="none"
            disabled={isDisabled}
            error={
              isDirty && invalidFields.includes('zipCode')
                ? 'Invalid zip code'
                : null
            }
            label="Zip code"
            onChange={onUpdateZipCode}
            tabIndex={7}
            value={contractor?.zipCode}
          />
        </FieldBox>
      </FieldsWrapper>

      <FieldsWrapper>
        <FieldBox>
          <MoneyField
            active
            disabled={isDisabled}
            error={
              isDirty && invalidFields.includes('estimatedCostOfWork')
                ? 'Invalid estimate (must be greater than 0)'
                : null
            }
            label="Estimated cost of work"
            onChange={(v) => onUpdate('estimatedCostOfWork', v)}
            tabIndex={8}
            value={contractor?.estimatedCostOfWork}
          />
        </FieldBox>
      </FieldsWrapper>

      <FieldsWrapper>
        <FieldBox>
          <Textarea
            active
            disabled={isDisabled}
            error={
              isDirty ? getRequiredMessage('scopeOfWork', invalidFields) : null
            }
            label="Brief description of the scope of work for this contractor."
            onChange={(v) => onUpdate('scopeOfWork', v)}
            rows={5}
            tabIndex={9}
            value={contractor?.scopeOfWork}
          />
        </FieldBox>
        <FieldBox css={{width: 'calc(33% - 16px)'}}>
          <TextField
            active
            disabled={isDisabled}
            error={
              isDirty && invalidFields.includes('website')
                ? 'Invalid URL format'
                : null
            }
            label="Website"
            help={<em>Optional</em>}
            onChange={(v) => onUpdate('website', v)}
            tabIndex={10}
            value={contractor?.website}
          />
        </FieldBox>
      </FieldsWrapper>
    </>
  );
};

AutocompleteFields.propTypes = {
  contractor: PropTypes.shape({
    businessName: PropTypes.string,
    city: PropTypes.string,
    contactName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    scopeOfWork: PropTypes.string,
    state: PropTypes.string,
    streetAddress: PropTypes.string,
    website: PropTypes.string,
    zipCode: PropTypes.string,
    estimatedCostOfWork: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  invalidFields: PropTypes.arrayOf(PropTypes.string),
  isDirty: PropTypes.bool,
  isEditing: PropTypes.bool,
  onUpdatePhoneNumber: PropTypes.func.isRequired,
  onUpdateEmail: PropTypes.func.isRequired,
  onUpdateZipCode: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default AutocompleteFields;
