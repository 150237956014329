import React from 'react';

import {initializeApp} from '@renofi/modules-internal';
import initPolyfills from '@renofi/utils/src/polyfills';

import Root from './modules/root/Root';
import * as appConfig from './config';
import setupLocalization from './lib/setupLocalization';
import initAnalytics from './analytics';
import writeInitialData from './initialData';
import './index.css';

initializeApp({
  appConfig: {
    ...appConfig,
    mockQueries: [],
  },
  onInit: async ({config, client, storage}) => {
    initPolyfills();
    setupLocalization();
    await initAnalytics({config, storage});
    await writeInitialData({client, storage});
  },
  rootComponent: <Root />,
});
