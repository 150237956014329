import {noop} from '@renofi/utilities';

import {MARK_TASK_DATA_READY_FOR_PROCESSING} from '../mutations';
import {useUpdateCachedTask} from '../utils';

import useMutation from './useMutation';

export default function useMarkTaskDataAsReadyForProcessing({
  onCompleted = noop,
  refetchQueries = [],
  updateCache = true,
} = {}) {
  const updateCachedTask = useUpdateCachedTask();
  const [mutation, {data, loading, error}] = useMutation(
    MARK_TASK_DATA_READY_FOR_PROCESSING,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = data?.task || null;

  const markTaskDataAsReadyForProcessing = async (args) => {
    const rsp = await mutation(args);
    const task = rsp?.data?.markTaskFinishedByBorrower?.task;
    const projectId = task?.project?.id;

    if (Boolean(rsp?.errors) || !updateCache || !task || !projectId) {
      return rsp;
    }

    await updateCachedTask({projectId, task});

    return rsp;
  };

  return {markTaskDataAsReadyForProcessing, response, loading, error};
}
