import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {REQUEST_SOFT_CREDIT_CHECK_FOR_PROJECT} from '../../../mutations/renovation';

export default function useRequestSoftCreditCheckForProject({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [requestSoftCreditCheckForProject, {data, loading, error}] =
    useMutation(REQUEST_SOFT_CREDIT_CHECK_FOR_PROJECT, {
      onCompleted,
      refetchQueries,
    });
  const response = propOr(null, ['requestSoftCreditCheckForProject'], data);
  return {requestSoftCreditCheckForProject, response, loading, error};
}
