import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {SUBMIT_SMS_CONSENT_RENOVATION_PROGRESS} from '../mutations';

import useMutation from './useMutation';

export default function useSubmitSmsConsentRenovationProgress({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitSmsConsent, {data, loading, error}] = useMutation(
    SUBMIT_SMS_CONSENT_RENOVATION_PROGRESS,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = pathOr(
    null,
    ['submitSmsConsentRenovationProgress', 'smsConsentRenovationProgress'],
    data,
  );
  return {submitSmsConsent, response, loading, error};
}
