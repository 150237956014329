import {useEffect} from 'react';

import {isNil} from 'lodash';

import {
  Box,
  Checkbox,
  Flex,
  MoneyField,
  SelectField,
  Text,
} from '@renofi/components-internal';
import {basic20, basic55} from '@renofi/theme';

import useReoEnums from './hooks/useReoEnums';
const RightText = (
  <Text color={basic20} textAlign="right">
    / month
  </Text>
);

const MortgagePaymentsStep = ({
  isSubjectProperty = false,
  property = {},
  onChange,
}) => {
  const {occupancyTypes, propertyTypes} = useReoEnums();
  const {
    existingMortgageLoan, // Boolean
    homeownerMonthlyInsurance, // Float
    homeownersAssociationFee, // Float
    mortgageBalance, // Float
    mortgageMonthlyInsurance, // Float
    mortgageMonthlyPayment, // Float
    otherMonthlyExpenses,
    propertyMonthlyTaxes, // Float
    propertyStructureType,
    propertyUseType,
  } = property;

  useEffect(() => {
    onChange(
      'homeownersAssociationFeesExist',
      Boolean(homeownersAssociationFee),
    );
  }, [homeownersAssociationFee]);

  const onChangeValue = (key) => (value) => {
    onChange(key, value);
  };

  const onCheck = (value) => {
    onChange({
      existingMortgageLoan: value,
      ...(isNil(propertyMonthlyTaxes) ? {propertyMonthlyTaxes: 0} : {}),
      ...(isNil(homeownerMonthlyInsurance)
        ? {homeownerMonthlyInsurance: 0}
        : {}),
    });
  };

  return (
    <Box p={3}>
      <Text mb={3} fontSize={24}>
        Mortgage Details
      </Text>

      {isSubjectProperty ? (
        <Flex css={{gap: 16}} width={1}>
          <Box flex={0.5}>
            <SelectField
              label="Property Type"
              onChange={onChangeValue('propertyStructureType')}
              options={propertyTypes}
              value={propertyStructureType}
            />
          </Box>
          <Box flex={0.5}>
            <SelectField
              label="Current Occupancy"
              onChange={onChangeValue('propertyUseType')}
              options={occupancyTypes}
              value={propertyUseType}
            />
          </Box>
        </Flex>
      ) : null}

      <Checkbox
        mb={24}
        checked={existingMortgageLoan}
        label="This property has mortgage(s)"
        onChange={onCheck}
      />

      <MoneyField
        emptyAsNull
        disabled={!existingMortgageLoan}
        label="Total Mortgage Unpaid Balance"
        onChange={onChangeValue('mortgageBalance')}
        small
        name="mortgageBalance"
        value={mortgageBalance}
        validations={['required']}
        showValidation={existingMortgageLoan}
      />

      <MoneyField
        emptyAsNull
        disabled={!existingMortgageLoan}
        label="Monthly mortgage payment"
        rightText={RightText}
        onChange={onChangeValue('mortgageMonthlyPayment')}
        small
        name="mortgageMonthlyPayment"
        value={mortgageMonthlyPayment}
        validations={['required']}
        showValidation={existingMortgageLoan}
      />

      <Box mb={3}>
        <Text fontSize={15} as="em" color={basic55}>
          if not included in the monthly mortgage payment:
        </Text>
      </Box>

      <Flex css={{gap: 16}} mb={3} width={1}>
        <MoneyField
          emptyAsNull
          name="propertyMonthlyTaxes"
          label="Property taxes"
          mb={0}
          onChange={onChangeValue('propertyMonthlyTaxes')}
          rightText={RightText}
          small
          value={propertyMonthlyTaxes}
          validations={['required', {invalidCharacter: '$0'}]}
          showValidation={!existingMortgageLoan}
        />

        <MoneyField
          emptyAsNull
          value={homeownerMonthlyInsurance}
          label="Homeowner insurance"
          mb={0}
          name="homeownerMonthlyInsurance"
          onChange={onChangeValue('homeownerMonthlyInsurance')}
          rightText={RightText}
          small
          validations={['required', {invalidCharacter: '$0'}]}
          showValidation={!existingMortgageLoan}
        />
      </Flex>

      <Flex css={{gap: 16}} mb={3} width={1}>
        <MoneyField
          emptyAsNull
          label="Mortgage insurance"
          disabled={!existingMortgageLoan}
          mb={0}
          name="mortgageMonthlyInsurance"
          onChange={onChangeValue('mortgageMonthlyInsurance')}
          rightText={RightText}
          small
          value={mortgageMonthlyInsurance}
        />

        <MoneyField
          emptyAsNull
          label="HOA"
          mb={0}
          name="homeownersAssociationFee"
          onChange={onChangeValue('homeownersAssociationFee')}
          rightText={RightText}
          small
          value={homeownersAssociationFee || 0}
        />
      </Flex>

      <Box mb={3} width={['100%', 'calc(50% - 8px)']}>
        <MoneyField
          emptyAsNull
          label="Other"
          mb={0}
          name="otherMonthlyExpenses"
          onChange={onChangeValue('otherMonthlyExpenses')}
          rightText={RightText}
          small
          value={otherMonthlyExpenses || 0}
        />
      </Box>
    </Box>
  );
};

export default MortgagePaymentsStep;
