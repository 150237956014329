import React, {useState, useEffect} from 'react';

import {isNil} from 'lodash';

import {useToggledBorrowerProject} from '@renofi/graphql';
import {sendEvent} from '@renofi/analytics';
import {Modal} from '@renofi/components-internal';
import {taskTypes} from '@renofi/utilities/src/enums';
import isNilOrEmpty from '@renofi/utilities/src/isNilOrEmpty';

import {validateByCurrentStep} from './utils';
import StepFooter from './components/StepFooter';
import getStepComponentByStepNumber from './components/getStepComponentByStepNumber';
import OnboardingContext from './context';

const Onboarding = () => {
  const {project} = useToggledBorrowerProject();
  const {
    borrowersAndPropertyInformation: propertyInfo,
    id: projectId,
    tasks,
  } = project || {};
  const {id: borrowersDetailsId, propertyStreetAddressOne} = propertyInfo || {};
  const onboardingTask = tasks?.find(
    (task) => task?.taskType === taskTypes.borrowers_onboarding,
  );

  const [info, setInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const isAddressSet = Boolean(propertyStreetAddressOne);

  const [step, setStep] = useState(0);
  const StepComponent = getStepComponentByStepNumber(step);

  const onUpdate = (attributes) => {
    setInfo({...info, ...attributes});
  };

  const isNextEnabled = validateByCurrentStep({info, step});

  useEffect(() => {
    setInfo(propertyInfo);

    if (isNilOrEmpty(propertyInfo) || isNil(onboardingTask)) {
      return setShowModal(false);
    }

    const {applicantSubmittedAt, taskId} = propertyInfo || {};
    const isPendingInfo = !applicantSubmittedAt && Boolean(taskId);
    setShowModal(isPendingInfo);
  }, [JSON.stringify(propertyInfo), JSON.stringify(onboardingTask)]);

  useEffect(() => {
    if (showModal) {
      sendEvent('Secure/Onboarding-Splash-Presented');
    }
  }, [showModal]);

  return (
    <OnboardingContext.Provider
      value={{borrowersDetailsId, info, isNextEnabled, setStep, step}}>
      <Modal
        p={0}
        lock
        width={920}
        minHeight={450}
        show={showModal}
        fixed={false}
        role="borrower-onboarding-modal"
        showHeaderCloseButton={false}
        headerCss={{justifyContent: 'center'}}
        footerCss={{display: 'flex', justifyContent: 'flex-end'}}
        footer={
          <StepFooter
            info={info}
            step={step}
            isFilled={isAddressSet}
            projectId={projectId}
            setShowModal={setShowModal}
          />
        }>
        <StepComponent
          info={info}
          setStep={setStep}
          borrowersDetailsId={borrowersDetailsId}
          projectId={projectId}
          update={onUpdate}
        />
      </Modal>
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
