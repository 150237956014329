import {hasPasswordProtectedDocs} from 'modules/tasks-wizard';
import {isNil, noop} from 'lodash';

const hasValueByMortgage = (value, existingMortgage) => {
  const isValue = !isNil(value);

  return existingMortgage ? isValue : isValue && value > 0;
};

const validateMortgageDetails = ({property} = {}) => {
  if (isNil(property)) {
    return false;
  }

  const {
    existingMortgageLoan,
    homeownerMonthlyInsurance,
    mortgageBalance,
    mortgageMonthlyPayment,
    propertyMonthlyTaxes,
    propertyStructureType,
    propertyUseType,
  } = property || {};

  const hasPropertyStatuses = Boolean(propertyStructureType && propertyUseType);
  const hasInsurance = hasValueByMortgage(
    homeownerMonthlyInsurance,
    existingMortgageLoan,
  );
  const hasTaxes = hasValueByMortgage(
    propertyMonthlyTaxes,
    existingMortgageLoan,
  );
  let hasMortgagePayments = true;

  if (existingMortgageLoan) {
    hasMortgagePayments =
      !isNil(mortgageBalance) && !isNil(mortgageMonthlyPayment);
  }

  return hasPropertyStatuses && hasInsurance && hasTaxes && hasMortgagePayments;
};

function validate({route, formData, task} = {}) {
  const {
    subjectProperty,
    additionalProperties,
    additionalPropertyNumber,
    soldProperties,
    soldPropertyNumber,
  } = formData;

  if (route.includes('subject-property')) {
    const {documentIds} = task.realEstateOwned?.subjectProperty || {};
    if (route === 'subject-property/mortgage-details') {
      return validateMortgageDetails({
        property: subjectProperty,
      });
    }

    if (route === 'subject-property/mortgage-statement') {
      return findDocumentType(task, documentIds, 'mortgage_statement');
    }

    if (route === 'subject-property/tax') {
      return findDocumentType(task, documentIds, 'property_tax');
    }

    if (route === 'subject-property/fees') {
      return findDocumentType(task, documentIds, 'homeowners_association_fees');
    }

    if (route === 'subject-property/insurance') {
      return findDocumentType(task, documentIds, 'homeowners_insurance_policy');
    }
  }

  if (route === 'additional') {
    return !isNil(additionalPropertyNumber);
  }

  if (route.includes('additional/')) {
    const property = findSubItem(route, additionalProperties, true);
    const index = findIndex(route, true);
    const {documentIds} =
      task.realEstateOwned?.additionalProperties[index] || {};

    if (route.includes('/address')) {
      return validateAddress(property);
    }

    if (route.includes('/details')) {
      return Boolean(
        !isNil(property) &&
          property.propertyStructureType &&
          property.propertyUseType &&
          (property.propertyUseType !== 'not_occupied' ||
            property.intendedOccupancy) &&
          (property.propertyUseType === 'not_occupied' ||
            property.propertyStatus !== 'rental' ||
            (property.propertyStatus === 'rental' && property.rentalIncome)) &&
          property.marketValue &&
          property.yearPurchased,
        // (property.primaryBorrowerOwned || property.coborrowerOwned),
      );
    }

    if (route.includes('/mortgage-details')) {
      return validateMortgageDetails({property});
    }

    if (route.includes('/mortgage-statement')) {
      return findDocumentType(task, documentIds, 'mortgage_statement');
    }

    if (route.includes('/tax')) {
      return findDocumentType(task, documentIds, 'property_tax');
    }

    if (route.includes('/insurance')) {
      return findDocumentType(task, documentIds, 'homeowners_insurance_policy');
    }

    if (route.includes('/fees')) {
      return findDocumentType(task, documentIds, 'homeowners_association_fees');
    }
  }

  if (route === 'sold') {
    return !isNil(soldPropertyNumber);
  }

  if (route.includes('sold/')) {
    const property = findSubItem(route, soldProperties);

    if (route.includes('/address')) {
      return validateAddress(property);
    }

    if (route.includes('/details')) {
      return Boolean(
        !isNil(property) &&
          property.propertyStructureType &&
          property.soldDate &&
          !isNaN(Date.parse(`01/${property.soldDate}`)),
      );
    }
  }
}

function validateAddress(property) {
  return Boolean(
    !isNil(property) &&
      property.state &&
      property.city &&
      property.streetAddressOne &&
      property.zipCode &&
      property.zipCode.length === 5,
  );
}

function findDocumentType(task, documentIds = [], type) {
  const taskDocs = task?.documents || [];
  const documents = taskDocs.filter(
    (doc) => documentIds.includes(doc.id) && doc.documentType === type,
  );

  return Boolean(documents.length) && !hasPasswordProtectedDocs(documents);
}

function findSubItem(route, array, isAdditional) {
  if (!array || !array.length) return {};
  const index = findIndex(route, isAdditional);
  return index >= 0 ? array[index] : {};
}

function findIndex(route, isAdditional) {
  const id = route.split('/')[1];
  return id - (isAdditional ? 2 : 1);
}

function validateAndUpdate(route, {formData, setComplete, task} = {}) {
  const isValid = validate({route, formData, task});
  setComplete(route, isValid);
}

export default function validateAll({
  steps = [],
  formData,
  setComplete = noop,
  task,
} = {}) {
  steps.forEach(({id, children}) => {
    validateAndUpdate(id, {formData, setComplete, task});
    children?.forEach(({id: childId}) =>
      validateAndUpdate(childId, {
        formData,
        setComplete,
        task,
      }),
    );
  });
}
