import React, {memo, useContext, useMemo} from 'react';

import PropTypes from 'prop-types';
import {isEmpty, pathOr} from 'ramda';
import {context as ProjectTasksContext} from 'lib/projectTasks';
import {FacetIntro} from 'modules/layout';

import Card from '@renofi/components-internal/src/next/Card';
import {Alert} from '@renofi/components-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {ReconfContext} from '@renofi/utilities/src/reconf';
import {taskTypes} from '@renofi/utilities/src/enums';

import useApplicationState from '../../hooks/useApplicationState';
import useTaskHandlers from '../../hooks/useTaskHandlers';
import useDocumentHandlers from '../../hooks/useDocumentHandlers';
import {TASK_CATEGORIES} from '../Project/constants';
import Modals from '../Modals';
import {WizardUploadTask} from '../Task';

import {RealEstateTask} from './components/RealEstate';
import AssetsTask from './components/AssetsTask/AssetsTask';
import BorrowerInformation from './Borrowers';
import EmploymentHistoryWizard from './components/EmploymentHistoryWizard';
import GovIdWizard from './components/GovIdWizard';
import UploadDocumentWizards from './components/UploadDocumentWizards';
import EditTaskButton from './components/EditTaskButton';
import ExtraDetails from './components/ExtraDetails';
import {IGNORE_TASK_TYPES} from './constants';
import {getCanShowDocuments, sortByConfig} from './utils';

const getWizardByType = ({canHaveDocument, taskType}) => {
  switch (true) {
    case taskType === taskTypes.employment_history:
      return EmploymentHistoryWizard;
    case taskType === taskTypes.government_issued_id:
      return GovIdWizard;
    case canHaveDocument:
      return UploadDocumentWizards;
    default:
      return null;
  }
};

const getTasks = (c = {}) =>
  []
    .concat(c?.eligibility || [])
    .concat(c?.borrowerInformation || [])
    .filter((t) => !IGNORE_TASK_TYPES.includes(t.taskType));

export const INCOME_AND_ASSETS = [
  taskTypes.employment_history,
  taskTypes.income,
  taskTypes.real_estate_owned,
  taskTypes.assets,
  taskTypes.additional_eligibility_evidence,
  taskTypes.mortgage_statement,
];

const facet = TASK_CATEGORIES.ELIGIBILITY;

const BorrowerDetails = ({onTaskComplete}) => {
  const {project, projectId} = useToggledBorrowerProject();
  const {config} = useContext(ReconfContext) || {};
  const tasksContext = useContext(ProjectTasksContext);

  const [borrowerDetails, incomeAndAssets] = useMemo(() => {
    const {taskOrderingByFacet: ordering = {}} = config;
    const allTasks = getTasks(tasksContext);
    const incomeAndAssets = allTasks?.filter(({taskType}) =>
      INCOME_AND_ASSETS.includes(taskType),
    );

    const otherTasks = allTasks.reduce((arr, task) => {
      const found = incomeAndAssets.some((t) => t.taskType === task.taskType);
      return found ? arr : arr.concat(task);
    }, []);

    return [
      sortByConfig(ordering?.borrowerInformation)(otherTasks),
      sortByConfig(ordering?.eligibility)(incomeAndAssets),
    ];
  }, [JSON.stringify(config), JSON.stringify(tasksContext)]);

  const {
    onConfirmUpload,
    onShowPrompt,
    onRemoveDocument,
    loading,
    removedDocument,
    uploadedDocument,
  } = useTaskHandlers({tasks: tasksContext.allTasks, projectId, facet});

  const {onAcceptFiles} = useDocumentHandlers({
    tasks: tasksContext.allTasks,
    projectId,
    facet,
  });

  const {
    applicationState,
    onCloseRejection,
    onCloseAdditionalRequest,
    onShowRejection,
  } = useApplicationState();

  const hasCoBorrower = pathOr(
    false,
    ['borrowersAndPropertyInformation', 'coborrowerIsPresent'],
    project,
  );

  return (
    <>
      <Modals
        tasks={tasksContext.allTasks}
        loading={loading}
        rejection={applicationState?.rejection}
        removeIds={removedDocument}
        addFilesData={uploadedDocument}
        additionalRequest={applicationState?.additionalRequest}
        onAcceptFiles={onAcceptFiles}
        onConfirmUpload={onConfirmUpload}
        onShowPrompt={onShowPrompt}
        onRemoveDocument={onRemoveDocument}
        onCloseRejection={onCloseRejection}
        onCloseAdditionalRequest={onCloseAdditionalRequest}>
        <BorrowerInformation
          onTaskComplete={onTaskComplete}
          showCoBorrowerIcon={hasCoBorrower}
          tasks={borrowerDetails}
        />
        {!isEmpty(incomeAndAssets) ? (
          <Card p={0}>
            <FacetIntro title="Income and assets">
              <Alert>
                Please provide documents about your income, assets, debt, and
                other information that will allow us to determine your
                eligibility for a new RenoFi loan.
              </Alert>
            </FacetIntro>
            {incomeAndAssets.map((task) => {
              switch (task.taskType) {
                case taskTypes.real_estate_owned:
                  return <RealEstateTask key={task.id} task={task} />;
                case taskTypes.assets:
                  return (
                    <AssetsTask
                      key={task.id}
                      task={task}
                      hasCoBorrower={hasCoBorrower}
                      project={project}
                    />
                  );
                default:
                  return (
                    <WizardUploadTask
                      customAddEditButton={EditTaskButton}
                      extra={ExtraDetails}
                      onRemoveFile={(value) =>
                        onShowPrompt({documentId: value, taskId: task.id})
                      }
                      onShowRejection={(documentId) =>
                        onShowRejection({documentId, taskId: task.id})
                      }
                      onTaskComplete={onTaskComplete}
                      key={task.id}
                      showCoBorrowerIcon={hasCoBorrower}
                      showTaskDocuments={getCanShowDocuments(task)}
                      showDetails
                      task={task}
                      wizard={getWizardByType(task)}
                    />
                  );
              }
            })}
          </Card>
        ) : null}
      </Modals>
    </>
  );
};

BorrowerDetails.propTypes = {
  onTaskComplete: PropTypes.func.isRequired,
};

export default memo(BorrowerDetails);
