import {useContext, useEffect} from 'react';

import {useFlag, useUnleashContext} from '@unleash/proxy-client-react';
import {pathOr} from 'ramda';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {useFeatureFlagEvents} from 'lib/hooks';
import {
  context as ProjectTasksContext,
  useAllProjectTasks,
} from 'lib/projectTasks';
import {Drawer as NavigationDrawer} from 'modules/layout';
import ProjectOverview from 'modules/project';
import FacetTasks from 'modules/tasks';
import Lender from 'modules/lender';
import RenovationProgress from 'modules/renovation';
import Review from 'modules/review';
import Delighted from 'modules/delighted';
import Onboarding from 'modules/onboarding';

import {
  Footer,
  Loader,
  RenoFiDesignCardMobile,
} from '@renofi/components-internal';
import {actionRegular, renofiBlue} from '@renofi/theme';
import {Context as ConfigContext} from '@renofi/utilities/src/config';
import {useGetUser, useToggledBorrowerProject} from '@renofi/graphql';
import {setAnalyticsIdentify} from '@renofi/analytics';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import isImpersonation from '@renofi/utilities/src/isImpersonation';

import {Container, Content, FooterContainer, LegalWrapper} from '../styled';

import LandscapeSvg from './BackImg';

const ProjectRouter = () => {
  const {loading, project} = useToggledBorrowerProject();
  const {user} = useGetUser();

  const match = useRouteMatch();
  const isRenoFiDesignEnabled = useFlag('secure-renofi-design');
  const updateContext = useUnleashContext();
  const projectTasks = useAllProjectTasks();
  const config = useContext(ConfigContext);
  const storage = useContext(StorageContext);
  useFeatureFlagEvents();

  const {PRODUCTION} = config;

  useEffect(() => {
    const borrowerId = pathOr(null, ['borrower', 'id'], project);
    const impersonation = isImpersonation(storage);

    if (borrowerId) {
      const {renovationCost} = project;
      updateContext({
        userId: borrowerId,
        renovationCost,
      });
    }

    if (Boolean(project && user) && PRODUCTION && !impersonation) {
      const {id, email, firstName, lastName} = user;
      const {loanType} = project;
      setAnalyticsIdentify({id, email, firstName, lastName, loanType});
    }
  }, [JSON.stringify({project, user}), window.logsReady]);

  if (loading) {
    return <Loader />;
  }

  return (
    <ProjectTasksContext.Provider value={projectTasks}>
      <NavigationDrawer />
      {isRenoFiDesignEnabled && <RenoFiDesignCardMobile />}

      <Container role="project-router-container">
        <Content>
          <Switch>
            <Route exact path={`${match.path}/:step(tasks)`}>
              <ProjectOverview />
            </Route>
            <Route path={`${match.path}/:step(tasks)/:facet?`}>
              <FacetTasks />
            </Route>
            <Route path={`${match.path}/:step(lender)`}>
              <Lender />
            </Route>
            <Route
              path={`${match.path}/:step(renovation-progress)/:action(add_update)?/:renovationUpdateStatus(expected_renovation_start|behind_schedule|on_schedule|ahead_of_schedule|renovation_completed|renovation_started)?`}>
              <RenovationProgress />
            </Route>
            <Route path={`${match.path}/:step(finished)`}>
              <Review />
            </Route>
            <Redirect to={`${match.path}/tasks`} />
          </Switch>
        </Content>
      </Container>

      <Onboarding />
      <Delighted />

      <FooterContainer>
        <LandscapeSvg />

        <LegalWrapper>
          <Footer color={renofiBlue} linkColor={actionRegular} maxWidth={760} />
        </LegalWrapper>
      </FooterContainer>
    </ProjectTasksContext.Provider>
  );
};

export default ProjectRouter;
