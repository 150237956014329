import useToggledQuery from '../../useToggledQuery';
import {GET_SUPPORTED_FILE_EXTENSIONS} from '../../queries';

export default function useSupportedFileExtensions({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_SUPPORTED_FILE_EXTENSIONS, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch, refetch} = response;
  const supportedFileExtensions = data?.supportedFileExtensions || [];

  return {error, fetch, refetch, supportedFileExtensions, loading};
}
