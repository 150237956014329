import styled from '@emotion/styled';

import {maxSmall, mediaQuery} from '@renofi/theme';
import {Flex} from '@renofi/components-internal';

export const Container = styled.div(
  mediaQuery({
    background:
      'linear-gradient(180deg, #F7FCFF 0%, #E9F5FD 75.52%, #F7FCFF 100%)',
    minHeight: '100vh',
    marginLeft: [0, 300],
    marginBottom: 50,
    paddingTop: [85, 0],
    paddingLeft: [0, 16],
    paddingRight: [0, 16],
  }),
);

export const Content = styled(Flex)({
  width: '100%',
  justifyContent: 'center',
  margin: '0 auto',
});

export const FooterContainer = styled(Flex)({
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-end',
});

export const LegalWrapper = styled(Flex)({
  paddingTop: 24,
  paddingBottom: 24,
  background: '#E7F9E9',
  marginLeft: [0, 300],
  justifyContent: 'center',

  [maxSmall]: {
    marginLeft: 8,
  },
});
