import React from 'react';

import PropTypes from 'prop-types';
import {useInputFieldAnalytics} from 'lib/hooks';
import {pick} from 'lodash';

import {FullAddressForm} from '@renofi/components-internal';

const AddressForm = ({onChange, value}) => {
  const [analyticsHandlers] = useInputFieldAnalytics();

  function onChangeValue(item) {
    onChange({
      ...value,
      ...pick(item, [
        'city',
        'county',
        'state',
        'streetAddressOne',
        'streetAddressTwo',
        'zipCode',
      ]),
    });
  }

  return (
    <FullAddressForm
      analytics={analyticsHandlers}
      autoAddressSearch
      data={value}
      onChange={(key, value) => onChangeValue({[key]: value})}
      onFullAddressChange={onChangeValue}
    />
  );
};

AddressForm.propTypes = {
  value: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    streetAddressOne: PropTypes.string,
    streetAddressTwo: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default AddressForm;
