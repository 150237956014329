import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {format} from 'date-fns';
import {getDisplayValue, setUpstreamValue} from 'lib/dates';

import {SimpleDateField} from '@renofi/components-internal';
import {noop} from '@renofi/utilities';

export const DATE_PLACEHOLDER = 'MM/DD/YYYY';
export const DATE_MASK = DATE_PLACEHOLDER.replace(/\//gi, '').split('');

const DateField = ({onChange, onError, value, width, ...props}) => {
  const displayValue = useMemo(() => {
    const isDate = value instanceof Date;
    if (isDate) {
      return getDisplayValue(format(value, 'yyyy-MM-dd'));
    }

    return getDisplayValue(value);
  }, [value]);

  const onChangeDate = (date, isValid) => {
    if (!isValid) {
      onError(isValid);
      return onChange('');
    }

    onChange(setUpstreamValue(date));
  };

  return (
    <SimpleDateField
      format="MM/dd/yyyy"
      placeholder={DATE_PLACEHOLDER}
      mask={DATE_MASK}
      onChange={(...args) => onChangeDate(...args)}
      value={displayValue}
      css={{width}}
      {...props}
    />
  );
};

DateField.propTypes = {
  onChange: PropTypes.func,
  onError: PropTypes.func,
  value: PropTypes.string,
  width: PropTypes.number,
};

DateField.defaultProps = {
  onChange: noop,
  onError: noop,
  value: '',
};

export default DateField;
