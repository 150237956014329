import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {BORROWERS_ONBOARDING_FINISHED_BY_APPLICANT} from '../../mutations';

export default function useBorrowersOnboardingFinishedByApplicant({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [borrowersOnboardingFinishedByApplicant, {data, loading, error}] =
    useMutation(BORROWERS_ONBOARDING_FINISHED_BY_APPLICANT, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(
    null,
    ['borrowersOnboardingFinishedByApplicant'],
    data,
  );
  return {borrowersOnboardingFinishedByApplicant, response, loading, error};
}
