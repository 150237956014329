import React from 'react';

import PropTypes from 'prop-types';

import {Flex} from '@renofi/components-internal';
import {TaskDocument} from '@renofi/modules-internal';
import {filterItemsByBorrowerRole} from '@renofi/utilities';

import {Wrapper} from '../../../Task/styled';

const BorrowerDocuments = ({
  taskDocuments,
  isPrimaryBorrower,
  setCurrentDocumentId,
  onShowRejection,
  onRemoveFile,
}) => {
  const borrowerRole = isPrimaryBorrower ? 'borrower' : 'coborrower';
  const docsToShow = filterItemsByBorrowerRole(taskDocuments, {borrowerRole});

  return (
    <Flex width={1} flexWrap="wrap">
      {docsToShow.map(({id, ...document}, i) => (
        <Wrapper key={id} even={i % 2 === 0} mt={12}>
          <TaskDocument
            {...document}
            id={id}
            onClick={() => setCurrentDocumentId(id)}
            onShowRejection={() => onShowRejection(id)}
            onRemove={() => onRemoveFile(id)}
            immutable
          />
        </Wrapper>
      ))}
    </Flex>
  );
};

BorrowerDocuments.propTypes = {
  taskDocuments: PropTypes.array,
  isPrimaryBorrower: PropTypes.bool,
  setCurrentDocumentId: PropTypes.func,
  onShowRejection: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

export default BorrowerDocuments;
