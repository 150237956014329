import React from 'react';

import PropTypes from 'prop-types';

import {TaskDocument} from '@renofi/modules-internal';
import {validateUuidProp} from '@renofi/utilities/src/react';
import {noop} from '@renofi/utilities';

import {DocumentWrapper, Wrapper} from './styled';

const DocumentList = ({
  documents = [],
  immutable = true,
  onClick = noop,
  onRemoveFile = noop,
  onShowRejection = noop,
}) => {
  return (
    <Wrapper width={1} flexWrap="wrap">
      {documents.map(({id, ...doc}, i) => {
        return (
          <DocumentWrapper key={id} even={i % 2 === 0}>
            <TaskDocument
              immutable={immutable}
              {...doc}
              id={id}
              onClick={() => onClick(id)}
              onRemoveFile={onRemoveFile}
              onShowRejection={onShowRejection}
            />
          </DocumentWrapper>
        );
      })}
    </Wrapper>
  );
};

DocumentList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: validateUuidProp,
    }),
  ),
  immutable: PropTypes.bool,
  onClick: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onShowRejection: PropTypes.func,
};

export default DocumentList;
