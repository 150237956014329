import React from 'react';

import PropTypes from 'prop-types';

import {
  EmploymentHistoryDetails,
  IncomeSourcesDetails,
} from '@renofi/modules-internal';
import {useToggledBorrowerProject} from '@renofi/graphql';
import {noop, useFeatureFlags} from '@renofi/utilities';
import {taskTypes} from '@renofi/utilities/src/enums';

import GovIdTaskDetails from '../GovIdTaskDetails';
import {customWizardIdVar} from '../utils';

const ExtraDetails = ({onClickDocument = noop, task}) => {
  const {documents, governmentIssuedIds, status, taskType} = task;
  const {project} = useToggledBorrowerProject();
  const {flags} = useFeatureFlags(['employmentHistoryDocuments']);
  const isEmploymentDocsEnabled = Boolean(flags?.employmentHistoryDocuments);
  const isTaskCompleted = status === 'completed';
  const {borrowersAndPropertyInformation = {}} = project || {};
  const hasCoBorrower = Boolean(
    borrowersAndPropertyInformation?.coborrowerIsPresent,
  );

  const onClickHistory = () => {
    customWizardIdVar(task.id);
  };

  switch (taskType) {
    case taskTypes.government_issued_id:
      return (
        <GovIdTaskDetails
          hasCoBorrower={hasCoBorrower}
          documents={documents}
          govIdDetails={governmentIssuedIds}
          setCurrentDocumentId={onClickDocument}
          completedAt={isTaskCompleted}
        />
      );
    case taskTypes.employment_history:
      return (
        <EmploymentHistoryDetails
          borrowerName={project?.primaryApplicant?.fullName}
          coborrowerName={project?.coborrower?.fullName}
          onClickDocument={onClickDocument}
          onClick={onClickHistory}
          showDocuments={isEmploymentDocsEnabled}
          task={task}
        />
      );

    case taskTypes.income:
      return (
        <IncomeSourcesDetails
          onClickDocument={onClickDocument}
          project={project}
          task={task}
        />
      );
    case taskTypes.identity_verification:
      return <p />;
    default:
      return null;
  }
};

ExtraDetails.propTypes = {
  onClickDocument: PropTypes.func,
  task: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    employmentHistories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    governmentIssuedIds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    id: PropTypes.string,
    status: PropTypes.string,
    taskType: PropTypes.string,
  }),
};

export default ExtraDetails;
