import useToggledQuery from '../../useToggledQuery';
import {GET_SUPPORTED_CONTENT_TYPES} from '../../queries';

export default function useSupportedContentTypes({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_SUPPORTED_CONTENT_TYPES, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch, refetch} = response;
  const supportedContentTypes = data?.supportedContentTypes || [];

  return {error, fetch, refetch, supportedContentTypes, loading};
}
